import React, { useEffect, useState } from 'react';

import { Button, Img, Text } from 'components';
import Dropdown from 'components/Dropdown';
import { NavSelectBox } from 'components/NavSelectBox';
import secureStorage from 'hooks/secureStorage';
import InformationModal from 'modals/InformationModal';
import { useAuth } from 'pages/AuthContext';
import { Suspense } from 'react';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa6';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoCloseOutline, IoCloudDownloadOutline } from 'react-icons/io5';
import Modal from 'react-modal'; // Import the Modal component
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Container, Content } from 'rsuite';
import { downloadApp, refreshToken } from 'service/api';
import { useCustomConsole } from 'components/Fonctions';

const languageOptionsList = [{ value: '', label: 'Aucune option', href: '#' }];
const recrutementOptionsList = [
  { value: '', label: 'Aucune option', href: '#' },
];
const servicesOptionsList = [
  { value: 'Les demandes', label: 'Les demandes', href: '/service/demande' },
];
Modal.setAppElement('#root');
function AppLayout({ children }) {
  const navigate = useNavigate();
  const user_courant = secureStorage.getItem('user_info');
  const userObj = JSON.parse(user_courant);
  const { isAuthenticated, logout, login, user } = useAuth();
  //const { isAuthenticated, logout } = useAuth();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [reponsepdf, setReponsepdf] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const location = useLocation();
  const shouldShowFooter = [
    '/accueil-expert',
    '/psfcprojets',
    '/projets',
    '/foncierurbaineprojets',
    '/autresprojets',
  ].includes(location.pathname);
  const [ModalOpen, setModalOpen] = useState(false);

  useCustomConsole()

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  const downloadAppMobile = async (ENV) => {
    setIsDownloading(true)
    let os = getMobileOperatingSystem()
    console.log("OS", os)
    // Retourner l'appli Android si os est unknown
    os = os === 'unknown' ? 'Android' : os;
    const payload = { ENV, os }

    await downloadApp(payload).then((res) => {
      setIsDownloading(false)
    }).catch((err) => {
      console.error(err);
      setIsDownloading(false)
    });
    setIsDownloading(false)
  };

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleExitButtonClick = () => {
    setIsModalOpen(false);
  };

  const handleConfirmButtonClick = () => {
    //APPEL DE L'API DE REFRESH TOKEN POUR METTRE A JOUR
    const req = {};
    const userid = userObj?.id;
    const reponsemsg = '';

    refreshToken(userid, req)
      .then((res) => {
        if (res?.data?.code === '201') {
          secureStorage.setItem(
            'usertoken_session',
            res?.data?.data?.accessToken
          );
        }

        setReponsepdf(
          <>
            <div className="flex flex-col gap-5 items-center justify-start w-full">
              {res?.data?.code === '201' ? (
                <Img
                  className="h-[90px] w-[90px]"
                  src="images/img_completed1.svg"
                  alt="completedOne"
                />
              ) : (
                <Img
                  className="h-[90px] w-[90px]"
                  src="images/img_account_24_outline.svg"
                  alt="account"
                />
              )}
              <div className="flex flex-col items-center justify-start w-full">
                <Text
                  className="text-green_gray-800_01 text-center text-sm w-full"
                  size="txtInterRegular"
                >
                  {res?.data?.code === '201'
                    ? 'Votre session a été prolongée avec succès.'
                    : res?.data?.message}
                </Text>
              </div>
              <div className="flex flex-row gap-2 items-center justify-center w-full">
                <Button
                  className="cursor-pointer font-inter font-medium h-10 text-center text-xs w-[60px]"
                  onClick={handleExitButtonClick}
                  shape="round"
                  color="cyan_900"
                  size="md"
                  variant="fill"
                >
                  OK
                </Button>
              </div>
            </div>
          </>
        );
        setIsModalOpen(true);
      })
      .catch((err) => {
        console.error(err);
      });
    setIsModalOpen(false);
  };
  // Fonction de vérification de l'expiration du token
  function checkTokenExpiration() {
    // //console.log("checktoken");
    const token = secureStorage.getItem('usertoken_session');
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1])); // Décoder les données du token (base64)
      const expirationTime = tokenData.exp * 1000; // Convertir le temps d'expiration en millisecondes
      const currentTime = Date.now(); // Temps actuel en millisecondes
      const timeUntilExpiration = expirationTime - currentTime; // Temps restant avant expiration en millisecondes
      const tenMinutes = 30 * 60 * 1000; // 30 minutes en millisecondes

      // Vérifiez si l'alerte a déjà été affichée
      const alertShown = secureStorage.getItem('alertShown');
      if (timeUntilExpiration <= tenMinutes && !alertShown) {
        // Si le temps restant est inférieur ou égal à 30 minutes et l'alerte n'a pas encore été affichée
        setReponsepdf(
          <>
            <div className="flex flex-col gap-5 items-center justify-start w-full">
              <Img
                className="h-[90px] w-[90px]"
                src="images/img_account_24_outline.svg"
                alt="account"
              />
              <div className="flex flex-col items-center justify-start w-full">
                <Text
                  className="text-green_gray-800_01 text-center text-sm w-full"
                  size="txtInterRegular"
                >
                  Votre session expirera dans 30 minutes. Voulez-vous prolonger
                  la session? Si oui, cliquez sur Prolonger. Si non, veuillez
                  enregistrer votre travail car votre session se terminera
                  bientôt.
                </Text>
              </div>{' '}
              <div className="flex flex-row gap-2 items-center justify-center w-full">
                <Button
                  className="cursor-pointer font-inter font-medium h-10 text-center text-xs w-[60px]"
                  onClick={handleExitButtonClick}
                  shape="round"
                  color="cyan_900"
                  size="md"
                  variant="fill"
                >
                  Non
                </Button>
                <Button
                  className="cursor-pointer font-inter font-medium h-10 text-center text-xs w-[110px]"
                  onClick={handleConfirmButtonClick}
                  shape="round"
                  color="cyan_900"
                  size="md"
                  variant="fill"
                >
                  Prolonger
                </Button>
              </div>
            </div>
          </>
        );
        setIsModalOpen(true);
        // Marquez l'alerte comme déjà affichée pour éviter qu'elle ne se répète
        secureStorage.setItem('alertShown', 'true');
        // Rediriger l'utilisateur vers la page de connexion après l'expiration du token
        setTimeout(() => {
          secureStorage.removeItem('isAuthenticated');
          secureStorage.removeItem('usertoken_session'); // Supprimer le token
          window.location.href = '/psfcseconnecter'; // Rediriger vers la page de connexion
        }, timeUntilExpiration);
      }
    }
  }
  // Vérifier token toutes les X minutes (par exemple, toutes les 1 minutes)
  const refreshInterval = 10 * 60 * 1000; // 1 minutes en millisecondes
  setInterval(checkTokenExpiration, refreshInterval);

  useEffect(() => {

    // À chaque chargement du composant, vérifiez l'expiration du token
    checkTokenExpiration();
  });

  // Define an array of route paths where you want to hide the header
  const routesWithoutHeader = [
    '/client',
    '/',
    '/psfcseconnecter',
    '/psfcsignin',
    '/psfcpamoforenqpromocontrataddcontrat',
    '/resumecontrat',
    '/addcf',
    '/editcf',
    '/addcf19',
    '/cf1',
    '/cf4',
    '/cf8',
    '/cf9',
    '/cf10',
    '/cf11',
    '/cf12',
    '/cf19',
    '/cf20',
    '/pvrdcTemplate',
    '/psfcmdpoublie',
    "/contact"
    //"/AdminHomePage"
  ];

  // Check if the current route is in the array
  const shouldShowHeader =
    !routesWithoutHeader.includes(window.location.pathname) &&
    !window.location.pathname.startsWith('/AdminHomePage');

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Function to toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {shouldShowHeader && (
        <header>
          <div
            id="mobile-menu-button"
            className="sm:flex  md:block hidden sm:flex-row md:flex-row md:justify-between sm:justify-between sm:gap-12 sm:items-center sm:p-4 md:p-6"
          >
            {/* Logo */}
            <div
              onClick={() => navigate('/accueil-expert')}
              className="sm:flex md:flex md:flex-1 sm:flex-1 sm:justify-start md:justify-start"
            >
              <img
                className="sm:w-36 md:w-36"
                src="/images/img_logocgeds1.png"
                alt="logocgedsOne"
              />
            </div>

            {/* Hamburger menu button */}
            <div className="md:text-3xl sm:text-3xl sm:text-black-900 md:text-black-900">
              <button onClick={toggleMobileMenu}>
                <GiHamburgerMenu />
              </button>
            </div>
          </div>

          <div
            id="mobile-menu"
            className={isMobileMenuOpen ? 'visible' : 'hidden'}
          >
            <div className="w-full sm:flex sm:flex-1 md:flex md:flex-1 sm:flex-col md:flex-col sm:justify-end md:justify-end sm:gap-3 sm:mr-4 md:mr-4">
              <Button
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                onClick={() => navigate('/accueil-expert')}
                size="smneuf"
                shape="round"
                variant="fill"
              >
                Accueil
              </Button>
              <Button
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                onClick={() => navigate('/projets')}
                size="smneuf"
                shape="round"
                variant="fill"
              >
                Projets
              </Button>

              <NavSelectBox
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                placeholderClassName="text-black-900"
                indicator={
                  <Img
                    className="h-6 w-6"
                    src="/images/img_arrowdown_black_900.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="language"
                options={languageOptionsList}
                isSearchable={false}
                placeholder="Relation client  "
                shape="round"
                color="white_A700"
                size="xsneuf"
                variant="fill"
              />
              <NavSelectBox
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                placeholderClassName="text-black-900"
                indicator={
                  <Img
                    className="h-6 w-6"
                    src="/images/img_arrowdown_black_900.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="recrutement"
                options={recrutementOptionsList}
                isSearchable={false}
                placeholder="Recrutement"
                shape="round"
                color="white_A700"
                size="xsneuf"
                variant="fill"
              />
              <NavSelectBox
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                placeholderClassName="text-black-900"
                indicator={
                  <Img
                    className="h-6 w-6"
                    src="/images/img_arrowdown_black_900.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="services"
                options={servicesOptionsList}
                isSearchable={false}
                placeholder="Services"
                shape="round"
                color="white_A700"
                size="xsneuf"
                variant="fill"
              />
             {/*  <Button
                className="font-bold font-inter leading-[normal] text-black-900 text-right text-sm"
                onClick={() => navigate('/contact')}
                size="smneuf"
                shape="round"
                variant="fill"
              >
                Contact
              </Button> */}
            </div>
            <div className="flex items-center justify-end space-x-4  sm:mr-4 md:mr-4 sm:mt-2 md:mt-2">
              <div className="relative">
                <img
                  className="h-8 w-8 cursor-pointer"
                  src="/images/img_profile24outline.svg"
                  alt="profile24outlin"
                  onClick={handleToggleDropdown}
                />
                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg"
                    style={{ backgroundColor: '#ffff', cursor: 'pointer' }}
                  >
                    {isAuthenticated && (
                      <>
                        <Button
                          className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                          style={{
                            color: '#000',
                            cursor: 'pointer',
                            font: 'bold',
                          }}
                          onClick={() => {
                            navigate('/psfcprofil');
                            handleToggleDropdown();
                          }}
                        >
                          Profil
                        </Button>

                        {user.userRole === 'ADMIN' &&
                          !user.userRole === 'ENQUETEUR' && (
                            <Button
                              className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                              onClick={() => {
                                navigate('/AdminHomePage');
                                handleToggleDropdown();
                              }}
                              style={{
                                color: '#000',
                                cursor: 'pointer',
                                font: 'bold',
                              }}
                            >
                              Tableau de bord
                            </Button>
                          )}

                        <Button
                          className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                          style={{
                            color: '#000',
                            cursor: 'pointer',
                            font: 'bold',
                          }}
                          onClick={() => {
                            navigate('/psfcprofil');
                            handleToggleDropdown();
                          }}
                        >
                          Changer le mot de passe
                        </Button>
                      </>
                    )}
                    <div onClick={() => navigate('/psfcseconnecter')}>
                      <Button
                        className="bg-transparent px-4 py-2 cursor-pointer leading-[normal] min-w-[108px] text-black-900 text-center text-sm"
                        size="md"
                        shape="round"
                        variant="fill"
                        onClick={isAuthenticated ? logout : null}
                        style={{
                          color: isAuthenticated ? 'red' : 'green',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                      >
                        {isAuthenticated ? 'Se déconnecter' : 'Se connecter'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <nav className="bg-white-A700 flex gap-2.5 items-center justify-center font-inter py-2.5 w-full">
            <div className="sm:hidden md:hidden flex md:flex-col flex-row md:gap-10 items-center justify-between pl-12 pr-12 max-w-8xl w-full">
              <div
                className="common-pointer flex md:flex-1 flex-col items-start justify-start md:w-full"
                onClick={() => navigate('/accueil-expert')}
              >
                <img
                  className="h-[49px] md:h-auto object-cover w-[126px]"
                  src="/images/img_logocgeds1.png"
                  alt="img_logocgeds1"
                />
              </div>
              <div className="flex md:flex-1 md:flex-col flex-row items-center justify-center w-full">
                <Button
                  className="common-pointer bg-transparent cursor-pointer font-bold font-inter leading-[normal] min-w-[52px] text-black-900 text-center text-sm"
                  onClick={() => navigate('/accueil-expert')}
                  size="smneuf"
                  shape="round"
                  variant="fill"
                >
                  Accueil
                </Button>
                <Button
                  className="common-pointer bg-transparent cursor-pointer font-bold font-inter leading-[normal] min-w-[49px] text-black-900 text-center text-sm"
                  onClick={() => navigate('/projets')}
                  size="smneuf"
                  shape="round"
                  variant="fill"
                >
                  Projets
                </Button>
                <div className="p-4">
                  <Dropdown
                    options={languageOptionsList}
                    buttonLabel="Relation client "
                  />
                </div>

                <div className="p-4">
                  <Dropdown
                    options={recrutementOptionsList}
                    buttonLabel="Recrutement"
                  />
                </div>

                <div className="p-4">
                  <Dropdown
                    options={servicesOptionsList}
                    buttonLabel="Services"
                  />
                </div>
                {/* <Button
                  className="common-pointer bg-transparent cursor-pointer font-bold font-inter leading-[normal] min-w-[52px] text-black-900 text-center text-sm"
                  onClick={() => navigate('/contact')}
                  size="smneuf"
                  shape="round"
                  variant="fill"
                >
                  Contact
                </Button> */}
              </div>
              <div className="flex items-center justify-end space-x-4">
                <div className="relative">
                  <img
                    className="h-8 w-8 cursor-pointer"
                    src="/images/img_profile24outline.svg"
                    alt="profile24outlin"
                    onClick={handleToggleDropdown}
                  />
                  {isDropdownOpen && (
                    <div
                      className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg"
                      style={{ backgroundColor: '#ffff', cursor: 'pointer' }}
                    >
                      {isAuthenticated && (
                        <>
                          <Button
                            className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                            style={{
                              color: '#000',
                              cursor: 'pointer',
                              font: 'bold',
                            }}
                            onClick={() => {
                              navigate('/psfcprofil');
                              handleToggleDropdown();
                            }}
                          >
                            Profile
                          </Button>

                          <>
                            {userObj?.role === 'ADMIN' && (
                              <Button
                                className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                                onClick={() => {
                                  navigate('/AdminHomePage');
                                  handleToggleDropdown();
                                }}
                                style={{
                                  color: '#000',
                                  cursor: 'pointer',
                                  font: 'bold',
                                }}
                              >
                                Dashboard
                              </Button>
                            )}
                          </>

                          <Button
                            className="block px-4 py-2 text-sm cursor-pointer leading-[normal] min-w-[108px] w-full text-left text-black-900"
                            style={{
                              color: '#000',
                              cursor: 'pointer',
                              font: 'bold',
                            }}
                            onClick={() => {
                              navigate('/psfcprofil');
                              handleToggleDropdown();
                            }}
                          >
                            Changer Mot de passe
                          </Button>
                        </>
                      )}
                      <div onClick={() => navigate('/psfcseconnecter')}>
                        <Button
                          className="bg-transparent px-4 py-2 cursor-pointer leading-[normal] min-w-[108px] text-black-900 text-center text-sm"
                          size="md"
                          shape="round"
                          variant="fill"
                          onClick={isAuthenticated ? logout : null}
                          style={{
                            color: isAuthenticated ? 'red' : 'green',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          {isAuthenticated ? 'Se déconnecter' : 'Se connecter'}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </header>
      )}
      <InformationModal isOpen={isModalOpen} message={reponsepdf} />
      <Suspense fallback={<Loader />}>{children}</Suspense>

      {shouldShowFooter && (
        <Container>
          <footer className="flex flex-row sm:flex-col md:flex-col p-8 sm:p-6 md:p-8 md:py-8 sm:py-6 py-12 justify-between px-24 bg-white">
            <div className="mb-8">
              <div onClick={() => navigate('/')}>
                <Img
                  className="h-[75px] md:h-auto object-cover w-[200px]"
                  src="images/img_logocgeds1.png"
                // alt="logocgedsOne"
                // alt="logocgedsOne"
                />
              </div>

              <div className="flex gap-8 text-2xl text-black-900 items-center mt-4">
                <FaFacebookF />
                <FaTwitter />
                <FaLinkedinIn />
              </div>

              <p className="text-black-900 font-medium text-sm mt-3">
                © 2024 cgeds. Tous droits réservés
              </p>
            </div>

            <div className="lg:px-24">
              <div className="flex text-xl mt-2 mb-4 items-center">
                <h1 className="text-black-900 font-extrabold">À propos</h1>
              </div>

              <ul className="tracking-wide px-2 font-light">
                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    À propos
                  </Link>
                </li>

                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Contact
                  </Link>
                </li>

                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Carrières
                  </Link>
                </li>
              </ul>
            </div>

            <div className="lg:px-24">
              <div className="flex text-xl mt-2 mb-4 items-center">
                <h1 className="text-black-900 font-extrabold">
                  Nous contacter
                </h1>
              </div>

              <ul className="tracking-wide px-2 font-light">
                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Site Web
                  </Link>
                </li>

                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Email
                  </Link>
                </li>
              </ul>
            </div>

            <div className="lg:px-24">
              <div className="flex text-xl mt-2 mb-4 items-center">
                <h1 className="text-black-900 font-extrabold">Support</h1>
              </div>

              <ul className="tracking-wide px-2">
                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    FAQ
                  </Link>
                </li>

                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Fonctionnement
                  </Link>
                </li>

                <li className="lg:mb-4">
                  <Link
                    to="/"
                    className="text-black-900 hover:text-[#135f76ab] text-md hover:no-underline mb-4"
                  >
                    Guideline
                  </Link>
                </li>
              </ul>
            </div>

            <div className="lg:px-24 gap-4">
              <div className="flex text-xl mt-2 mb-4 items-center">
                <h1 className="text-black-900 font-extrabold">S'abonner</h1>
              </div>

              <div
                className="bg-white items-center justify-between w-full mt-4 flex rounded-full sticky"
                style={{ top: '5px', width: '280px' }}
              >
                <input
                  type="text"
                  name="number"
                  id="number"
                  placeholder="Entrez votre e-mail"
                  className="bg-white items-center w-full flex text-black-900 rounded-lg shadow-gray-900 p-3 sticky border-none bg-gray-300 lg:placeholder-black-900"
                  required
                />
                <div className="bg-[#135f76ab] rounded-lg p-2 transform -translate-x-15">
                  <svg
                    className=""
                    height="30px"
                    width="30px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 503.82 503.82"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g transform="translate(1 1)">
                        <path
                          style={{ fill: '#ffff' }}
                          d="M491.909,112.838L221.64,311.763l167.869,109.115L502.82,114.517 C501.981,114.517,501.142,112.838,491.909,112.838"
                        ></path>
                        <path
                          style={{ fill: '#ffff' }}
                          d="M175.476,408.287l99.043-66.308"
                        ></path>
                        <g>
                          <polygon
                            style={{ fill: '#CCCCCC' }}
                            points="209.05,299.173 175.476,408.287 274.519,341.979 "
                          ></polygon>
                          <polygon
                            style={{ fill: '#CCCCCC' }}
                            points="209.05,299.173 176.316,408.287 150.296,265.599 494.427,89.336 "
                          ></polygon>
                          <path
                            style={{ fill: '#CCCCCC' }}
                            d="M486.034,104.445c-3.357,0-5.875-1.679-7.554-4.197L209.05,299.173l167.869,109.115 l111.633-303.843C488.552,104.445,487.712,104.445,486.034,104.445"
                          ></path>
                        </g>
                        <polygon
                          style={{ fill: '#ffff' }}
                          points="444.066,122.91 209.05,299.173 360.132,391.5 460.853,114.517 "
                        ></polygon>
                        <path
                          style={{ fill: '#FFFFFF' }}
                          d="M466.729,95.212L6.768,190.058l143.528,75.541l323.148-165.351 C470.086,99.409,468.407,97.73,466.729,95.212"
                        ></path>
                        <path
                          style={{ fill: '#ffff' }}
                          d="M444.066,114.517L32.788,206.845l117.508,58.754l299.646-146.046 C447.424,118.714,444.906,117.035,444.066,114.517"
                        ></path>
                        <g>
                          <path
                            style={{ fill: '#CCCCCC' }}
                            d="M376.919,416.681c-1.679,0-3.357-0.839-4.197-1.679L204.853,305.887 c-2.518-0.839-4.197-3.357-4.197-6.715c0-2.518,0.839-5.036,3.357-6.715l201.443-147.725l-250.964,128.42 c-2.518,0.839-5.036,0.839-7.554,0L3.411,197.612c-3.357-1.679-5.036-5.036-4.197-8.393c0-4.197,2.518-6.715,5.875-7.554 l486.82-100.721c1.679,0,2.518,0,4.197,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c1.679,0.839,3.357,1.679,5.036,3.357l0,0 l0,0c0,0,0,0,0,0.839c1.679,1.679,1.679,3.357,1.679,5.036l0,0l0,0c0,0.839,0,0.839,0,1.679v0.839L385.312,411.645 c-0.839,2.518-2.518,4.197-5.036,5.036C378.597,416.681,377.758,416.681,376.919,416.681z M224.158,298.333l148.564,96.525 l104.079-282.02L224.158,298.333z M31.948,193.415l118.348,62.951L435.673,110.32L31.948,193.415z"
                          ></path>
                          <path
                            style={{ fill: '#CCCCCC' }}
                            d="M175.476,416.681c-2.518,0-5.036-1.679-6.715-3.357c-2.518-4.197-1.679-9.233,2.518-11.751 l99.043-66.308c4.197-2.518,9.233-1.679,11.751,2.518c2.518,4.197,1.679,9.233-2.518,11.751l-99.043,66.308 C178.834,415.841,177.155,416.681,175.476,416.681z"
                          ></path>
                          <path
                            style={{ fill: '#CCCCCC' }}
                            d="M176.316,416.681C175.476,416.681,175.476,416.681,176.316,416.681 c-4.197,0-7.554-3.357-8.393-6.715l-26.02-142.689c-0.839-3.357,0.839-7.554,4.197-9.233L490.23,81.782 c4.197-1.679,8.393-0.839,10.911,3.357c2.518,3.357,1.679,8.393-2.518,10.911l-282.02,208.157L183.87,410.805 C183.03,414.163,179.673,416.681,176.316,416.681z M159.529,270.635l18.466,102.4l22.662-75.541 c0.839-1.679,1.679-3.357,3.357-4.197l201.443-147.725L159.529,270.635z"
                          ></path>
                          <path
                            style={{ fill: '#CCCCCC' }}
                            d="M175.476,416.681c-1.679,0-3.357-0.839-5.036-1.679c-2.518-2.518-4.197-5.875-3.357-9.233 l33.574-109.115c0.839-2.518,2.518-4.197,5.036-5.036c2.518-0.839,5.036-0.839,7.554,0.839l65.469,42.807 c2.518,1.679,4.197,4.197,4.197,6.715s-1.679,5.875-3.357,6.715l-99.043,66.308C178.834,415.841,177.155,416.681,175.476,416.681z M214.086,312.602l-23.502,75.541l68.826-46.164L214.086,312.602z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>

              <Modal
                isOpen={ModalOpen}
                onRequestClose={() => {
                  closeModal();
                }}
                style={{
                  overlay: {
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                  },
                  content: {
                    width: '50vw',
                    height: '60vh',
                    color: 'black',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'transparent',
                    border: 0,
                  },
                }}
              >
                <Content className="bg-white-A700 flex flex-row sm:flex-col md:flex-row justify-between shadow-gray-400 shadow-md rounded-lg">
                  <div className="absolute top-2 right-2">
                    <button
                      onClick={closeModal}
                      className="bg-gray-200 text-gray-600 rounded-full p-2 hover:bg-gray-300 focus:outline-none"
                      aria-label="Close"
                    >
                      <IoCloseOutline size={24} />
                    </button>
                  </div>
                  <div className="bg-[#135f76ab] text-white-A700 p-20 px-8 sm:px-4 md:px-6 w-1/2 sm:w-full sm:h-1/2 md:w-full rounded-l-lg">
                    <div>
                      <Img
                        className="h-full md:h-46 object-cover w-46 shadow rounded-lg"
                        src="https://i.ibb.co/thQZPwR/Pamofor-Prod-App-1.png"
                        alt="Code_Qr"
                      />
                    </div>
                  </div>

                  <div className="w-full p-8 mt-24 sm:mt-0 md:mt-12">
                    <h1 className="text-3xl sm:text-sm md:text-sm font-bold mb-4">
                      Téléchargez l'application CGEDS <br />
                      version mobile pour Android.
                    </h1>
                    {!isDownloading ? (<Button
                      className="bg-red-600 px-4 py-2 font-bold rounded-xl hover:bg-red-800 focus:outline-none text-lg sm:text-sm md:text-md shadow-xl flex items-center gap-2"
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                        font: 'bold',
                        backgroundColor: '#145c74',
                      }}
                      onClick={() =>
                        downloadAppMobile("PROD")
                      }
                    >
                      <IoCloudDownloadOutline />
                      Télécharger
                    </Button>) : (<Button
                      className="bg-red-600 px-4 py-2 font-bold rounded-xl hover:bg-red-800 focus:outline-none text-lg sm:text-sm md:text-md shadow-xl flex items-center gap-2"
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                        font: 'bold',
                        backgroundColor: '#145c74',
                      }}
                    >
                      <IoCloudDownloadOutline />
                      chargement...
                    </Button>)}

                  </div>
                </Content>
              </Modal>

              <div className="mt-4" style={{ width: '280px' }}>
                <Button
                  onClick={openModal}
                  className="bg-red-600 px-4  py-2 font-bold rounded-xl hover:bg-red-800 focus:outline-none text-lg shadow-xl flex items-center gap-2"
                  style={{
                    color: 'white',
                    cursor: 'pointer',
                    font: 'bold',
                    backgroundColor: '#145c74',
                    width: '280px',
                  }}
                >
                  <IoCloudDownloadOutline />
                  Télécharger l'app mobile
                </Button>
              </div>
            </div>
          </footer>
        </Container>
      )}
    </div>
  );
}
const Loader = () => {
  return (
    <div style={loaderStyles}>
      <PulseLoader color="#4890a6" loading={true} size={15} />
    </div>
  );
};

const loaderStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default AppLayout;
