import { Text } from 'components';
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for screen readers

function InformationModal({ isOpen, message }) {
  return (
    <Modal
      className="m-auto !w-[38%]"
      overlayClassName="bg-black-900_33 fixed flex h-full inset-y-[0] w-full"
      isOpen={isOpen}
      contentLabel="Information Modal"
    >
      <div className="overflow-y-auto sm:w-full md:w-full">
        <div className="bg-white-A700 flex flex-col md:h-auto items-center justify-center max-w-[965px] mx-auto my-[17px] p-[71px] md:px-5 rounded-[15px] w-full">
          <div className="flex flex-col items-start justify-start p-2 w-auto sm:w-full">
            <div className="flex flex-col gap-[5px] items-center justify-start sm:w-full">
              <div className="flex flex-col items-center justify-start w-auto">
                <Text
                  className="text-base text-center text-gray-700 py-10 w-auto"
                  size="txtInterBold"
                >
                  {message}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default InformationModal;
