import React from 'react';
import * as Sentry from '@sentry/browser';
import { env } from 'constantes/environement';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  

  componentDidCatch(error, errorInfo) {
    if (env.NODE_ENV === 'production') {
      Sentry.captureException(error, { extra: errorInfo });
    } else {
      console.error('Erreur capturée par ErrorBoundary:', error, errorInfo);
    }
  }


  render() {
    if (this.state.hasError) {
      // Vous pouvez personnaliser le message d'erreur ici
      return (
        <div>
          Une erreur est survenue. Veuillez contacter l'administrateur système
          ou réessyer plus tard.
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;


