import React from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import ErrorBoundary from './ErrorBoundary';
import Routes from './Routes';

function App() {
  return (
    
    <ErrorBoundary fa>
      <ProSidebarProvider>
        <Routes />
      </ProSidebarProvider>
    </ErrorBoundary>
  );
}

export default App;
