import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const Dropdown = ({ options, buttonLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Ajouter l'écouteur d'événements lorsque le composant est monté
    document.addEventListener('mousedown', handleClickOutside);

    // Nettoyer l'écouteur d'événements lorsque le composant est démonté
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        className="common-pointer bg-transparent cursor-pointer font-bold font-inter leading-[normal] min-w-[49px] text-black-900 text-center text-sm"
        onClick={toggleDropdown}
      >
        {buttonLabel} <ArrowDownLineIcon />
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          <div className="absolute top-[-8px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-4 border-r-4 border-t-4 border-t-white border-l-transparent border-r-transparent"></div>
          {options.map((option, index) => (
            <a
              key={index}
              href={option.href}
              className="block px-4 py-2 text-gray-800 bg-[#ffffff] hover:bg-[#368196] hover:text-[#ffffff] no-underline"
              style={{ textDecoration: 'none' }}
            >
              {option.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default Dropdown;
