import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'pages/AuthContext';

export const ProtectedRoute = ({ element: Element }) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  if (
    !isAuthenticated &&
    [
      '/psfcprojets',
      '/psfcpamofor',
      '/psfcpamoforenqpromocontratenquetecreee',
      '/resumecontrat',
      '/psfcpamoforcreerenqute',
      '/psfcpamoforenqpromocontrat',
      '/psfcpamoforenqpromocontrataddcontrat',
      '/psfcpamoforenqpromocontratupdatecontrat',
      '/Addcontratone',
      '/Addcontratoneupdate',
      '/psfcpamoforenqpromocontratcontratdonationptwo',
      '/psfcpamoforenqpromocontratcontratdonationptwoupdate',
      '/psfcpamoforenqpromocontratcontratdonationpthree',
      '/psfcpamoforenqpromocontratcontratdonationpthreeupdate',
      '/psfcpamoforenqpromocontratcontratdonationpfour',
      '/psfcpamoforenqpromocontratcontratdonationpfourupdate',
      '/psfcpamoforenqpromocontratcontratdonationpfive',
      '/psfcpamoforenqpromocontratcontratdonationpfiveupdate',
      '/psfcpamoforenqpromocontratcontratdonationpsix',
      '/psfcpamoforenqpromocontratcontratdonationpsixupdate',
      '/psfcpamoforenqpromocontratcontratdonationpseven',
      '/psfcpamoforenqpromocontratcontratdonationpsevenupdate',
      '/psfcpamoforenqpromocontratcontratdonationpeight',
      '/psfcpamoforenqpromocontratcontratdonationpeightupdate',
      '/psfcpamoforenqpromocontratcontratdonationpnine',
      '/psfcpamoforenqpromocontratcontratdonationpnineupdate',
      '/psfcpamoforenqpromocontratcontratdonationpten',
      '/psfcpamoforenqpromocontratcontratdonationptenupdate',
      '/psfcpamoforlivrablescf',
      '/psfcpamoforlivrablescla',
      '/rapport-deroulement',
      '/declarationouverture',
      '/psfcpamoforenqcf',
      '/addcfinfo',
      '/demandeenqcf',
      '/infooptcf',
      '/editdemandeenqcf',
      '/editinfooptcf',
      '/addpvcl',
      '/addannexespvcl',
      '/cf4',
      '/addannoncepub',
      '/addannexesannoncepub',
      '/cf8',
      '/addpvpub',
      '/addannexespvpub',
      '/cf9',
      '/addcecpdc',
      '/addannexescecpdc',
      '/cf10',
      '/addvalidation',
      '/addannexesvalidation',
      '/cf11',
      '/addclP',
      '/addannexesclP',
      '/cf12',
      '/addcf19',
      '/cf19',
      '/addouverturepub',
      '/addannexesouverturepub',
      '/cf20',
      '/pub',
      '/miseenoeuvredesopcf',
      '/fichedemographiquecf',
      '/QuestionnaireCF',
      '/InfoOccuppantsParcelle',
      '/AutresDeclarationsCF',
      '/ListeDesServitudes',
      '/EtatDesDroitsDeProp',
      '/FicheLitigesCF',
      '/pvrdc',
      '/listepvrdc',
      '/SignatairesDesignationGestionnaire',
      '/GestionnaireCF',
      '/editcf',
      '/consodispo/attestationformationaefj',
      '/consodispo/attestationformationaefc',
    ].includes(pathname)
  ) {
    return <Navigate to="/psfcseconnecter" />;
  }
  return <Element />;
};
