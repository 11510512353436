import React from 'react';

const sizeClasses = {
  txtInterRegular: 'font-inter font-normal',
  txtInterSemiBold: 'font-inter font-semibold',
  txtInterBold: 'font-bold font-inter',
  txtInterMedium: 'font-inter font-medium',
  txtPoppinsMedium: 'font-medium font-poppins',
  txtInterRegular12: 'font-inter font-normal',
  txtInterSemiBold12: 'font-inter font-semibold',
  txtInterBold12: 'font-bold font-inter',
  txtInterMedium12: 'font-inter font-medium',
  txtPoppinsMedium12: 'font-medium font-poppins',
  txtInterRegular14: 'font-inter font-normal',
  txtInterSemiBold14: 'font-inter font-semibold',
  txtInterBold14: 'font-bold font-inter',
  txtInterMedium14: 'font-inter font-medium',
  txtPoppinsMedium14: 'font-medium font-poppins',
  txtCalibriRegular: 'font-calibri font-normal',
  txtCalibriSemiBold: 'font-calibri font-semibold',
  txtCalibriBold: 'font-bold font-calibri',
  txtCalibriMedium: 'font-calibri font-medium',
  txtPoppinsMedium: 'font-medium font-poppins',
  txtCalibriRegular12: 'font-calibri font-normal',
  txtCalibriBold12: 'font-bold font-calibri',
  txtCalibriRegular14: 'font-calibri font-normal',
  txtCalibriBold14: 'font-bold font-calibri',
};

const Text = ({ children, className = '', size, as, ...restProps }) => {
  const Component = as || 'p';

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
