import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Remplacez useHistory par useNavigate
import Select from 'react-select';
import { ErrorMessage } from '../ErrorMessage';

const variants = {
  fill: {
    white_A700: 'bg-white-A700 text-black-900',
    cyan_900_19: 'bg-cyan-900_19',
  },
};
const shapes = { round: 'rounded', roundneuf: 'rounded-[10px]' };
const sizes = {
  xs: 'p-[7px]',
  sm: 'p-2.5',
  md: 'pb-4 pt-[6px] px-4',
  lg: 'py-[12px] px-[9px]',
  xsneuf: 'px-[7px] py-[1px]',
};

const SelectBox = React.forwardRef(
  (
    {
      defaultData,
      children,
      placeholder = 'Select',
      className = '',
      options = [],
      isSearchable = false,
      placeholderClassName = '',
      isMulti = false,
      onChange,
      value = '',
      errors = [],
      indicator,
      shape = '',
      size = '',
      variant = '',
      color = '',
      ...restProps
    },
    ref
  ) => {
    const navigate = useNavigate();
    const [selectedVal, setSelectedVal] = React.useState(value);

    const handleChange = (data) => {
      setSelectedVal(data);
      if (isMulti) {
        onChange?.(data?.map((d) => d.value) || []);
      } else {
        onChange?.(data?.value);
        // Rediriger vers la page correspondante
        if (data?.link) {
          navigate(data.link);
        }
      }
    };

    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} ${(shape && shapes[shape]) || ''} ${
            (size && sizes[size]) || ''
          } ${(variant && variants[variant]?.[color]) || ''}`}
          placeholder={
            <div className={placeholderClassName}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          value={selectedVal}
          onChange={handleChange}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 0,
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: 'transparent',
              border: '0 !important',
              boxShadow: '0 !important',
              minHeight: 'auto',
              '&:hover': {
                border: '0 !important',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected && '#fafafa',
              backgroundColor: state.isSelected && '#135f7619',
              '&:hover': { backgroundColor: '#135f7619', color: '#5d5a6f99' },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'inherit',
            }),
            input: (provided) => ({
              ...provided,
              color: 'inherit',
              margin: '0',
              padding: '0',
              // height: "0",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: '0',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingTop: '0px',
              paddingBottom: '0px',
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: '0',
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              padding: '0',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            placeholder: (base) => ({
              ...base,
              margin: 0,
            }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === 'scrollContainer';
          }}
          {...restProps}
        />
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shape: PropTypes.oneOf(['round']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'xsneuf']),
  variant: PropTypes.oneOf(['fill']),
  color: PropTypes.oneOf(['white_A700', 'cyan_900_19']),
};

SelectBox.defaultProps = {
  placeholder: 'Select',
  className: '',
  isSearchable: false,
  placeholderClassName: '',
  isMulti: false,
  value: '',
  shape: '',
  variant: '',
  color: '',
  size: '',
  options: [],
  onChange: () => {},
};
export { SelectBox };
