import { AuthProvider } from 'pages/AuthContext'; // Import your AuthProvider
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/color.css';
import './styles/font.css';
import './styles/index.css';
import './styles/tailwind.css';

// window.addEventListener('error', (event) => {
//   if (event.message.includes('ResizeObserver loop')) {
//     console.log("event", event.message)
//     event.stopImmediatePropagation();
//   }
// });

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
