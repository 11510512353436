import { Img } from 'components';
import { React, useEffect } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'rsuite';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'CGEDS - Page non trouvée';
  });

  return (
    <Container>
      <div
        className="flex items-center justify-center h-1/2 bg-cover bg-white-4700 bg-center"
        style={{}}
      >
        <div className="text-center text-white">
          <Img
            className="w-full h-1/2 mx-auto"
            src="https://img.freepik.com/free-vector/404-error-with-landscape-concept-illustration_114360-7968.jpg"
            alt="Page Not Found"
          />
          <h1 className="text-5xl sm:text-3xl text-[#113946] font-bold mb-8">
            Oups ! Page non trouvée
          </h1>

          {/* <p className="text-lg mb-8">
            It seems like we ran out of code or the page you're looking for doesn't exist.
          </p>
 */}
          <Button
            onClick={() => {
              navigate('/');
            }}
            className="bg-[#113946] px-6 py-4 gap-2 items-center font-bold rounded-full hover:bg-blue-600 focus:outline-none text-xl sm:text-md shadow-2xl shadow-gray-300"
            style={{
              color: 'white',
              cursor: 'pointer',
              font: 'bold',
              backgroundColor: '#113946',
            }}
          >
            Retourner à la page d'accueil
            <FaArrowRightLong className="text-md" />
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
