import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage } from '../../components/ErrorMessage';

const shapes = { square: 'rounded-none' };
const sizes = {
  xs: 'pb-[3px] pl-[3px] pt-[5px]',
  sm: 'pb-1.5 pl-1.5 pt-[7px]',
  md: 'pb-[5px] pt-2 px-[5px]',
};

const CheckBox = React.forwardRef(
  (
    {
      inputClassName = '',
      className = '',
      name = '',
      children,
      label = '',
      errors = [],
      shape = 'square',
      size = 'xs',
      variant = '',
      color = '',
      id = 'checkbox_id',
      onChange,
      ...restProps
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (onChange) onChange(e?.target?.checked);
    };

    return (
      <>
        <div className={className}>
          <input
            className={`${inputClassName} ${(shape && shapes[shape]) || ''} ${
              (size && sizes[size]) || ''
            }`}
            ref={ref}
            type="checkbox"
            name={name}
            onChange={handleChange}
            {...restProps}
            id={id}
          />
          <label htmlFor={id}>{label}</label>
        </div>
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);

CheckBox.propTypes = {
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  shape: PropTypes.oneOf(['square']),
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
};

export { CheckBox };
