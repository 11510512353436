import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage } from '../../components/ErrorMessage';

const variants = {
  fill: {
    white_A700: 'bg-white-A700 text-gray-700',
    cyan_900_19: 'bg-cyan-900_19',
  },
};
const shapes = { round: 'rounded' };
const sizes = {
  xs: 'p-[3px]',
  sm: 'pb-[35px] pt-[5px] px-[5px]',
  md: 'p-[7px]',
  lg: 'pb-2.5 pt-[9px] px-[9px]',
  xl: 'pb-5 pt-[15px] px-[15px]',
  '2xl': 'pb-4 pl-4 pr-3 pt-[19px]',
  '3xl': 'pb-[17px] pt-[18px] px-[17px]',
};

const Input = React.forwardRef(
  (
    {
      disableInput,
      wrapClassName = '',
      className = '',
      name = '',
      placeholder = '',
      type = 'text',
      children,
      errors = [],
      label = '',
      prefix,
      suffix,
      onChange,
      onBlur,
      shape = '',
      size = '',
      variant = '',
      color = '',
      ...restProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = React.useState('');

    const handleChange = (e) => {
      if (onChange) onChange(e?.target?.value);
      setInputValue(e?.target?.value);
    };

    const handleBlur = () => {
      if (type === 'text') {
        const supprespacedbtfin = inputValue.trim();
        const supprespacemilieu = supprespacedbtfin.replace(/\s+/g, ' ');
        setInputValue(supprespacemilieu);
      }
    };

    return (
      <>
        <div
          className={`${wrapClassName} 
              ${shapes[shape] || ''} 
              ${variants[variant]?.[color] || ''} 
              ${sizes[size] || ''}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            disabled={disableInput}
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf(['round']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
  variant: PropTypes.oneOf(['fill']),
  color: PropTypes.oneOf(['white_A700', 'cyan_900_19']),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export { Input };
