import LZString from 'lz-string';

const compresStorage = {
  
  setItem: (key, value) => {
    try {
      const compressedValue = LZString.compress(JSON.stringify(value));
      localStorage.setItem(key, compressedValue);
    } catch (error) {
      console.error('Error setting item in compresStorage', error);
    }
  },
  getItem: (key) => {
    try {
      const compressedValue = localStorage.getItem(key);
      if (compressedValue) {
        const decompressedValue = JSON.parse(
          LZString.decompress(compressedValue)
        );
        return decompressedValue;
      }
      return null;
    } catch (error) {
      console.error('Error getting item from compresStorage', error);
      return null;
    }
  },
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing item from compresStorage', error);
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing compresStorage', error);
    }
  },
};

export default compresStorage;
