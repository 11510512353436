import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage } from '../../components/ErrorMessage';

const Radio = React.forwardRef(
  (
    {
      inputClassName = '',
      className = '',
      name = '',
      children,
      label = '',
      checked = '',
      errors = [],
      onChange,
      id = 'radio_id',
      ...restProps
    },
    ref
  ) => {
    const [value, setValue] = React.useState(checked);

    const handleChange = (event) => {
      setValue(event.target.checked);
      if (onChange) onChange(!!event?.target?.checked);
    };

    return (
      <>
        <div className={className}>
          <input
            className={`${inputClassName}`}
            ref={ref}
            type="radio"
            name={name}
            aria-checked={!!value}
            checked={!!value}
            onChange={handleChange}
            {...restProps}
            id={id}
          />
          <label htmlFor={id}>{label}</label>
        </div>
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);

Radio.propTypes = {
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export { Radio };
