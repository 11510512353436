import secureStorage from 'hooks/secureStorage';
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(
    secureStorage.getItem('isAuthenticated') === 'true'
  );

  const [user, setUser] = useState({});

  const login = (userRole, reset_pswd) => {
    secureStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);

    // Redirect based on user role using window.location.href
    if (userRole === 'ADMIN') {
      window.location.href = '/AdminHomePage';
      setUser({ userRole: 'ADMIN' });
    } else if (userRole === 'ENQUETEUR') {
      if (reset_pswd === true) window.location.href = '/psfcprofil';
      else window.location.href = '/accueil-expert';
      // else window.location.href = '/psfcprojets';
      setUser({ userRole: 'ENQUETEUR' });
    } else {
      // Default redirection if role is not recognized
      window.location.href = '/';
    }
  };

  const logout = () => {
    secureStorage.removeItem('isAuthenticated');
    // Perform logout operations, e.g., remove the token
    secureStorage.removeItem('usertoken_session');
    secureStorage.removeItem('user_info');
    secureStorage.removeItem('signature_com_enq');
    secureStorage.removeItem('user_role');

    // Update isAuthenticated to false
    setIsAuthenticated(false);

    // Redirect to the login page after logout using window.location.href
    window.location.href = '/psfcseconnecter';
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
