import Axios from 'axios';
import secureStorage from 'hooks/secureStorage';

const defaultAxios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYTNmOTI0NTNjODViYzEyNjU4ZjNiZSIsInVzZXJuYW1lIjoiSnVkZ2VfQ3JvbmluIiwiaWF0IjoxNjcxNjk3MTcxfQ.hbZLKSsS6Mdj1ndhAf4rm_5we4iWYvKY1VPSo51sQRM',
  },
});

defaultAxios.interceptors.request.use(
  async (config) => {
    if (secureStorage.getItem('usertoken_session'))
      // token utilisateur à chaque requete
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${secureStorage.getItem('usertoken_session')}`,
      };

    return config;
  },
  (error) => Promise.reject(error)
);

defaultAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  // (err) => {
  //   return Promise.reject(err);
  // }
  (err) => {
    // Check for a 401 status code
    if (err.response && err.response.status === 401) {
      secureStorage.removeItem('isAuthenticated');
      secureStorage.removeItem('usertoken_session');
      window.location.href = '/psfcseconnecter';
    }

    return Promise.reject(err);
  }
);

export function apiClient(method, url, options = {}) {
  const { data = {}, headers = {}, params = {}, ...rest } = options;
  return defaultAxios({
    url,
    data,
    method,
    params,
    headers,
    ...rest,
  });
}

export const apis = {
  get: (url, args) => apiClient('get', url, args),
  post: (url, args) => apiClient('post', url, args),
  put: (url, args) => apiClient('put', url, args),
  patch: (url, args) => apiClient('patch', url, args),
  delete: (url, args) => apiClient('delete', url, args),
};
