import axios from 'axios';
import { apis } from 'service';

export const COMMON_URL = `https://api.service1.digital.cgeds.com/pamofor/`;
export const REDASH_COMMON_URL = "https://redash.cgeds.com/"

const GEOMATIC_URL = `https://pamofor.goazimut.com/api/`;
const GEOMATIC_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjcyMzY4MDAsInVzZXJuYW1lIjoiZ2xvYmFsZXhwZXJ0aXNlIiwic2VydmljZXMiOltdLCJpYXQiOjE2OTU2NjgyMTB9.H0KmSPbt8xqOfwgvpolKMbgHh06TyqGR1uM8ndLT9qs`;

const API_URLS = {
  UPDATE_ANNONCE_PUB: `${COMMON_URL}certificat-foncier/update-anp`,
  LIST_PUB: `${COMMON_URL}certificat-foncier/anp-by-enquete`,
  LIST_PUB_UNIQUE: `${COMMON_URL}certificat-foncier/anp`,
  GENERATE_CONTRAT: `${COMMON_URL}contrat/generate-contrat-pdf`,
  ADD_SERVITUDE: `${COMMON_URL}pvrdc/servitude`,
  ADD_ETAT_DE_DROIT: `${COMMON_URL}pvrdc/etat-droit`,
  UPDATE_ETAT_DE_DROIT: `${COMMON_URL}pvrdc/update-etat-droit`,
  DELETE_VALID: `${COMMON_URL}constat/delete-valid`,
  DELETE_CECPDC: `${COMMON_URL}constat/delete-cecpdc`,
  DELETE_ANP: `${COMMON_URL}certificat-foncier/delete-anp`,
  DELETE_ANPC: `${COMMON_URL}certificat-foncier/delete-anpc`,
  DELETE_ANPO: `${COMMON_URL}certificat-foncier/delete-anpo`,
  DELETE_CLP: `${COMMON_URL}clp/delete`,
  DELETE_PVCL: `${COMMON_URL}pvcl/delete`,
  DELETE_DCF: `${COMMON_URL}certificat-foncier/delete-dcf`,
  TRANSMISSION_DCF: `${COMMON_URL}certificat-foncier/dcf-transmission-byNNNNN`,
  TRANSMISSION_DCF_ENQUETE: `${COMMON_URL}certificat-foncier/dcf-transmission-byVillage`,
  DELETE_CF: `${COMMON_URL}certificat-foncier/delete-cf19`,
  GET_NUM_CONTRAT_ENQUETE: `${COMMON_URL}contrat/numero-contrats/by-enquete`,
  UPDATE_PVCL: `${COMMON_URL}pvcl/update-pvcl`,
  DELETE_ANNEXES_PVCL: `${COMMON_URL}pvcl/delete-lppc`,
  UPDATE_ANNEXES_PVCL: `${COMMON_URL}pvcl/update-lppc`,
  GET_PARCELLE_GEOMATIC: `${GEOMATIC_URL}parcelle`,
  UPDATE_DEMANDE_CF: `${COMMON_URL}certificat-foncier/update-certificat`,
  POST_PDF_CLP: `${COMMON_URL}clp/upload-clp-pdf`,
  GET_CLP: `${COMMON_URL}clp/find-one`,
  POST_ANNEXES_CLP: `${COMMON_URL}clp/clp-lppc`,
  POST_CLP: `${COMMON_URL}clp/new`,
  GET_ALL_NUM_DCF: `${COMMON_URL}certificat-foncier/get-all-nnnnn`,
  GET_PPD_SHP: `${COMMON_URL}certificat-foncier/download-shapeppd`,
  GET_PPP_SHP: `${COMMON_URL}certificat-foncier/download-shapeppp`,
  GET_PPD_PDF: `${COMMON_URL}certificat-foncier/ppdef/download-pdf`,
  GET_PPP_PDF: `${COMMON_URL}certificat-foncier/ppprov/download-pdf`,
  UPDATE_INFO_CF: `${COMMON_URL}certificat-foncier/refresh-de-cf`,
  UPDATE_STATUS_DEMANDE: `${COMMON_URL}certificat-foncier/refresh-anonce-pub-demandeur`,
  UPDATE_ETAT_DEMANDE: `${COMMON_URL}certificat-foncier/refresh-etat-dcf`,
  POST_PDF_CF: `${COMMON_URL}certificat-foncier/upload-cf`,
  POST_DEMANDES_PUB: `${COMMON_URL}certificat-foncier/anpd`,
  PDF_DEMANDES_PUB: `${COMMON_URL}certificat-foncier/create-anp-pdf`,
  POST_CLOTURE_PUB: `${COMMON_URL}certificat-foncier/anpc`,
  UPDATE_PV_CLOTURE_PUB: `${COMMON_URL}certificat-foncier/update-anpc`,
  ANNONCE_PUB: `${COMMON_URL}certificat-foncier/anp`,
  POST_PDF_PVO: `${COMMON_URL}certificat-foncier/upload-pvo`,
  POST_PVO_PRESENCE: `${COMMON_URL}certificat-foncier/dcf-pvo-lp`,
  POST_PVO: `${COMMON_URL}certificat-foncier/dcf-pvo`,
  POST_ANPO: `${COMMON_URL}certificat-foncier/anpo`,
  UPDATE_ANPO: `${COMMON_URL}certificat-foncier/update-anpo`,
  READ_PDF_CF: `${COMMON_URL}certificat-foncier/read-file`,
  POST_PDF_CONSTAT: `${COMMON_URL}constat/upload-constat-pdf`,
  GET_CONSTAT: `${COMMON_URL}constat/find-one`,
  POST_ANNEXES_CONSTAT: `${COMMON_URL}constat/add-person`,
  POST_CONSTAT: `${COMMON_URL}constat/new`,
  POST_PDF_CLOTUREPUB: `${COMMON_URL}certificat-foncier/upload-anpc`,
  CREATE_CLOTUREPUB_PUB: `${COMMON_URL}certificat-foncier/create-anpc-pdf`,
  POST_PDF_PUB: `${COMMON_URL}certificat-foncier/upload-anp`,
  POST_PDF_PVCL: `${COMMON_URL}pvcl/upload-pvcl-pdf`,
  GET_PVCL: `${COMMON_URL}pvcl/find-one`,
  POST_ANNEXES_PVCL: `${COMMON_URL}pvcl/lppc`,
  POST_PVCL: `${COMMON_URL}pvcl/new`,
  ADD_LISTE_SERVITUDE: `${COMMON_URL}pvrdc/servitude`,
  POST_PVRDC: `${COMMON_URL}pvrdc/new`,
  ADD_MEMBRE_EQUIPE_ENQ_PVRDC: `${COMMON_URL}pvrdc/equipe-enquete`,
  UPDATE_MEMBRE_EQUIPE_ENQ_PVRDC: `${COMMON_URL}pvrdc/update/equipe-enquete`,
  DELETE_MEMBRE_EQUIPE_ENQ_PVRDC: `${COMMON_URL}pvrdc/delete/equipe-enquete`,
  POST_FICHE_DEMO_PVRDC: `${COMMON_URL}pvrdc/fiche-demographique`,
  ADD_DECLARATION_PVRDC: `${COMMON_URL}pvrdc/declaration`,
  ADD_LITIGE_PVRDC: `${COMMON_URL}pvrdc/fiche-identification-litige`,
  ADD_FICHE_LITIGE_PVRDC: `${COMMON_URL}pvrdc/fiche-litige`,
  UPDATE_FICHE_LITIGE_PVRDC: `${COMMON_URL}pvrdc/fiche-litige`,
  ADD_OCCUPANT_PARCELLE: `${COMMON_URL}pvrdc/add-dec-occupant`,
  DELETE_OCCUPANT_PARCELLE: `${COMMON_URL}pvrdc/delete-dec-occupant`,
  UPDATE_OCCUPANT_PARCELLE: `${COMMON_URL}pvrdc/update-dec-occupant`,
  UPDATE_OCCUP_PARCELLE_DATE: `${COMMON_URL}pvrdc/occupant-parcelle`,
  ADD_OCCUP_PARCELLE_DATE: `${COMMON_URL}pvrdc/occupant-parcelle`,
  ADD_ETAT_DE_DROIT_PROP: `${COMMON_URL}pvrdc/add-etat-droit`,
  DELETE_PVRDC_FICHE_DEMOGRAPHIQUE: `${COMMON_URL}pvrdc/annexe/remove-fiche-demo`,
  DELETE_PVRDC_ANNEXE_EQUIPE_ENQUETE: `${COMMON_URL}pvrdc/annexe/remove-equipe-enquete`,
  DELETE_PVRDC_ANNEXE_DECLARATION_OCCUPANT: `${COMMON_URL}pvrdc/annexe/remove-declaration-persone-auditionne`,
  DELETE_PVRDC_ANNEXE_OCCUPANT_PARCELLE: `${COMMON_URL}pvrdc/annexe/remove-occupant-personne`,
  DELETE_PVRDC_ANNEXE_FICHE_LITIGE: `${COMMON_URL}pvrdc/annexe/remove-fiche-litige`,
  DELETE_PVRDC_ANNEXE_DESIGNATION_GESTIONNAIRE: `${COMMON_URL}pvrdc/annexe/remove-designation-gestionnaire`,
  DELETE_PVRDC_ANNEXE_DETENTEUR_DROIT: `${COMMON_URL}pvrdc/annexe/remove-detenteur-droit`,
  DELETE_PVRDC_ANNEXE_ETAT_DROIT: `${COMMON_URL}pvrdc/annexe/remove-etat-droit`,
  DELETE_PVRDC: `${COMMON_URL}pvrdc/remove-pvrdc`,
  DELETE_PVRDC_ANNEXE_SERVITUDE: `${COMMON_URL}pvrdc/annexe/remove-annexe-servitude`,
  DELETE_PVRDC_ANNEXE_SIGNATURE_DESIGNATION_GESTIONNAIRE: `${COMMON_URL}pvrdc/annexe/remove-signature-designation-gestionnaire`,
  ADD_AYANT_DROIT: `${COMMON_URL}pvrdc/ayant-droit`,
  POST_PDF_PVRDC: `${COMMON_URL}pvrdc/upload-pvrdc-pdf`,
  GET_CF: `${COMMON_URL}certificat-foncier/dcf-one`,
  GET_PVRDC: `${COMMON_URL}certificat-foncier/pvrdc-by-dcf`,
  GET_DCF_CONSTATS: `${COMMON_URL}constat/dcf-constats`,
  GET_DCF_ANNONCE_PUBLICITE: `${COMMON_URL}certificat-foncier/annoncepub-by-certif`,
  POST_PDF_CFPIECE: `${COMMON_URL}certificat-foncier/upload-dcf-piece`,
  UPDATE_PDF_CFPIECE: `${COMMON_URL}certificat-foncier/update-dcf-piece`,
  POST_PDF_DCF: `${COMMON_URL}certificat-foncier/upload-dcf-pdf`,
  POST_DEMANDE_CF: `${COMMON_URL}certificat-foncier/new`,
  POST_DESIGNATION_GESTIONNAIRECFC: `${COMMON_URL}pvrdc/add-gbfc`,
  PUT_DESIGNATION_GESTIONNAIRECFC: `${COMMON_URL}pvrdc/update-gbfc`,
  POST_DCF_DEMANDE_CLIENT: `${COMMON_URL}certification-foncier/client/new-dcf`,
  ADD_SIGNATAIRE_BIEN_FONCIER: `${COMMON_URL}pvrdc/add-liste-gbfc`,
  ADD_DETENTEURS_DE_DROITS_CF: `${COMMON_URL}pvrdc/listeddcc`,
  LOG_ACTIVITY: `${COMMON_URL}log-activity/getLogsBy`,
  GET_CF_BY_ENQ: `${COMMON_URL}certificat-foncier/by-enquete`,
  POST_TEMOIN_CONTRAT: `${COMMON_URL}contrat/add-temoin`,
  UPDATE_TEMOIN_CONTRAT: `${COMMON_URL}contrat/update-temoin`,
  POST_PDF_CONTRAT: `${COMMON_URL}contrat/upload-contract`,
  POST_CECPDC_PDF: `${COMMON_URL}constat/generate-cecpdc-pdf`,
  POST_VALID_PDF: `${COMMON_URL}constat/generate-valid-pdf`,
  UPDATE_PROCURATION_CONTRAT: `${COMMON_URL}update/procuration`,
  POST_PDF_CNI_CONTRAT: `${COMMON_URL}contrat/file-pieces`,
  UPDATE_PDF_CNI_CONTRAT: `${COMMON_URL}update/file-pieces`,
  POST_PDF_PROCURATION_CONTRAT: `${COMMON_URL}contrat/upload-procuration`,
  UPDATE_PDF_PROCURATION_CONTRAT: `${COMMON_URL}update/file-pieces`,
  POST_PDF_CROQUIS_CONTRAT: `${COMMON_URL}contrat/upload-croquis`,
  POST_ANNEXES_CONTRAT: `${COMMON_URL}contrat/upload-annexe`,
  UPDATE_ANNEXES_CONTRAT: `${COMMON_URL}update/annexe`,
  UPDATE_ANNEXES_CROQUIS: `${COMMON_URL}update/croquis`,
  READ_PDF_PROCURATION: `${COMMON_URL}contrat-read/procuration`,
  READ_PDF_CROQUIS: `${COMMON_URL}contrat-read/croquis`,
  READ_PDF_CONTRAT: `${COMMON_URL}read-contract/file`,
  READ_PDF_ANNEXES: `${COMMON_URL}contrat-read/annexe/`,
  READ_PDF_PIECESID: `${COMMON_URL}contrat-read/piece/`,
  GET_CONTRAT_ENQ: `${COMMON_URL}contrat/by-enquete`,
  GET_CONTRAT: `${COMMON_URL}contrat/findone`,
  GET_CONTRAT_ALL: `${COMMON_URL}contrat/all`,
  GET_DELETE_CONTRAT: `${COMMON_URL}update/delete/`,
  POST_SOUSSIGNE_CONTRAT: `${COMMON_URL}contrat/add-person`,
  PUT_SOUSSIGNE_CONTRAT: `${COMMON_URL}contrat/update-person`,
  POST_CONTRAT: `${COMMON_URL}contrat/new`,
  UPDATE_CONTRAT: `${COMMON_URL}contrat/update-contrat`,
  GET_ENQUETE_USER: `${COMMON_URL}enquete/by-user`,
  GET_ENQUETE_ALL: `${COMMON_URL}enquete/all`,
  GET_ONLY_ENQUETE_ALL: `${COMMON_URL}enquete/all-enquete`,
  GET_COMMUNE_VIL: `${COMMON_URL}codification/commune-vil`,
  GET_SP_COMMUNE: `${COMMON_URL}codification/sp-commune`,
  GET_DEP_SP: `${COMMON_URL}codification/dep-sp`,
  GET_REGION_DEP: `${COMMON_URL}codification/region-dep`,
  GET_REGIONS: `${COMMON_URL}codification/regions`,
  POST_USER_RESEND_EMAIL: `${COMMON_URL}user/resend-email`,
  POST_USER_CONFIRM_EMAIL: `${COMMON_URL}user/confirm-email`,
  CHANGE_PSWD_USER: `${COMMON_URL}user/change-password`,
  FORGOT_PSWD_USER: `${COMMON_URL}user/forgot-password`,
  SIGNIN_USER: `${COMMON_URL}user/signin`,
  UPDATE_USER: `${COMMON_URL}user`,
  REFRESH_TOKEN_USER: `${COMMON_URL}user/refresh-token`,
  SIGNUP_USER: `${COMMON_URL}user/signup`,
  GET_ALL_USER: `${COMMON_URL}user/all`,
  POST_ENQUETE: `${COMMON_URL}enquete/new`,
  GET_ENQUETE_BY_CODE: `${COMMON_URL}enquete/by-code`,
  GET_ALL_CF: `${COMMON_URL}certificat-foncier/cf/all`,
  GET_ALL_DCF: `${COMMON_URL}certificat-foncier/list-dcf`,
  ADD_OTA: `${COMMON_URL}ota/create-ota`,
  GET_ALL_OTA: `${COMMON_URL}ota/all`,
  GET_ONE_OTA: `${COMMON_URL}ota`,
  POST_DECLARATION_OUVERTURE: `${COMMON_URL}clarification/declaration-ouverture/new`,
  POST_TRANSMISSION_LETTRE: `${COMMON_URL}clarification/transmission-lettre/new`,
  UPDATE_DECLARATION_OUVERTURE: `${COMMON_URL}clarification/declaration-ouverture/update`,
  UPDATE_TRANSMISSION_LETTRE: `${COMMON_URL}clarification/transmission-lettre/update`,
  DELETE_DO: `${COMMON_URL}clarification/declaration-ouverture/delete`,
  DELETE_TL: `${COMMON_URL}clarification/transmission-lettre/delete`,
  GET_TL: `${COMMON_URL}clarification/transmission-by-code`,
  POST_AEFC: `${COMMON_URL}consolidation/new/attestation-aefc`,
  POST_AEFJ: `${COMMON_URL}consolidation/new/attestation-aefj`,
  DOWNLOAD_MOBILE_APP: `${COMMON_URL}certificat-foncier/download-apk`,
  SEND_MESSAGE: `${COMMON_URL}contact/send-message`
  //PUT_DESIGNATION_GESTIONNAIRECFC: `${COMMON_URL}pvrdc/update-gbfc`,
};


export const contactApi = (payload) =>
  apis.post(API_URLS.SEND_MESSAGE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });


export const postDemandeClient = (payload) =>
  apis.post(API_URLS.POST_DCF_DEMANDE_CLIENT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const putDesignationGestionnaireCFC = (codepvrdc, payload) =>
  apis.put(`${API_URLS.PUT_DESIGNATION_GESTIONNAIRECFC}/${codepvrdc}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateDO = (payload) =>
  apis.post(API_URLS.UPDATE_DECLARATION_OUVERTURE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const findTransmissionByCodeCla = (code_cla, payload) => {
  const apiUrl = `${API_URLS.GET_TL}/${code_cla}`;
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updateTL = (payload) =>
  apis.post(API_URLS.UPDATE_TRANSMISSION_LETTRE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const deleteDO = (code_cla, payload) => {
  const apiUrl = `${API_URLS.DELETE_DO}/${code_cla}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteTL = (code_cla, payload) => {
  const apiUrl = `${API_URLS.DELETE_TL}/${code_cla}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const createAnpPdf = (payload) =>
  apis.post(API_URLS.PDF_DEMANDES_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postDeclarationOuverture = (payload) =>
  apis.post(API_URLS.POST_DECLARATION_OUVERTURE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postTransmissionLettre = (payload) =>
  apis.post(API_URLS.POST_TRANSMISSION_LETTRE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const get_list_pub_unique = (codepub, payload) => {
  const apiUrl = `${API_URLS.LIST_PUB_UNIQUE}/${codepub}`;

  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const get_list_pub = (code, payload) => {
  const apiUrl = `${API_URLS.LIST_PUB}/${code}`;

  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updateAnnoncePub = (payload) =>
  apis.post(API_URLS.UPDATE_ANNONCE_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const generateContrat = (payload) =>
  apis.post(API_URLS.GENERATE_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addServitude = (payload) =>
  apis.post(API_URLS.ADD_SERVITUDE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addDateServitude = (payload) =>
  apis.post(`${COMMON_URL}pvrdc/add-servitude`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateServitude = (code_pvrdc, payload) =>
  apis.put(`${COMMON_URL}pvrdc/update-servitude/${code_pvrdc}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addEtatDeDroit = (payload) =>
  apis.post(API_URLS.ADD_ETAT_DE_DROIT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateEtatDeDroitCF = (code, payload) => {
  const apiUrl = `${API_URLS.UPDATE_ETAT_DE_DROIT}/${code}`;

  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteVALID = (codevalid, payload) => {
  const apiUrl = `${API_URLS.DELETE_VALID}/${codevalid}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteCECPDC = (codececpdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_CECPDC}/${codececpdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteANP = (code_annonce_pub, payload) => {
  const apiUrl = `${API_URLS.DELETE_ANP}/${code_annonce_pub}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteANPC = (code_annonce_pub, payload) => {
  const apiUrl = `${API_URLS.DELETE_ANPC}/${code_annonce_pub}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteANPO = (code_annonce_pub, payload) => {
  const apiUrl = `${API_URLS.DELETE_ANPO}/${code_annonce_pub}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteCLP = (codeclp, payload) => {
  const apiUrl = `${API_URLS.DELETE_CLP}/${codeclp}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePVCL = (codepvcl, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVCL}/${codepvcl}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeFicheDemographique = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_FICHE_DEMOGRAPHIQUE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeEquipeEnquete = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_EQUIPE_ENQUETE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeDeclarationOccupant = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_DECLARATION_OCCUPANT}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeOccupantParcelle = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_OCCUPANT_PARCELLE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeFicheLitige = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_FICHE_LITIGE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeDetenteurDroit = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_DETENTEUR_DROIT}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeEtatDroit = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_ETAT_DROIT}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePVRDC = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeDesignationGestionnaire = (
  codepvrdc,
  payload
) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_DESIGNATION_GESTIONNAIRE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeServitude = (codepvrdc, payload) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_SERVITUDE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deletePvrdcAnnexeSignatureDesignationGestionnaire = (
  codepvrdc,
  payload
) => {
  const apiUrl = `${API_URLS.DELETE_PVRDC_ANNEXE_SIGNATURE_DESIGNATION_GESTIONNAIRE}/${codepvrdc}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteDCF = (codedcf, payload) => {
  const apiUrl = `${API_URLS.DELETE_DCF}/${codedcf}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const TransmissionDCF = (NNNNN, payload) => {
  const apiUrl = `${API_URLS.TRANSMISSION_DCF}/${NNNNN}`;

  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const TransmissionDCFbyenquete = (numero_enquete, payload) => {
  const apiUrl = `${API_URLS.TRANSMISSION_DCF_ENQUETE}/${numero_enquete}`;

  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteCF = (codedcf, payload) => {
  const apiUrl = `${API_URLS.DELETE_CF}/${codedcf}`;

  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getNumContratByEnquete = (ide, payload) => {
  const apiUrl = `${API_URLS.GET_NUM_CONTRAT_ENQUETE}/${ide}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updatePVCL = (payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.UPDATE_PVCL}`;
  // Make the GET request with the constructed URL
  return apis.post(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteAnnexesPVCL = (ida, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.DELETE_ANNEXES_PVCL}/${ida}`;
  // Make the GET request with the constructed URL
  return apis.delete(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updateAnnexesPVCL = (ida, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.UPDATE_ANNEXES_PVCL}/${ida}`;
  // Make the GET request with the constructed URL
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getParcelleGeomatic = async (num_dcf) => {
  const response = await axios.get(
    `${API_URLS.GET_PARCELLE_GEOMATIC}/${num_dcf}`,
    {
      headers: {
        Authorization: `Bearer ${GEOMATIC_TOKEN}`,
      },
    }
  );
  return response;
};

export const updateDemandeCF = (codedcf, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.UPDATE_DEMANDE_CF}/${codedcf}`;
  // Make the GET request with the constructed URL
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getAllNumDCF = (payload) =>
  apis.get(API_URLS.GET_ALL_NUM_DCF, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const refreshToken = (idu, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.REFRESH_TOKEN_USER}/${idu}`;
  // Make the GET request with the constructed URL
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const changePSWD = (idu, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.CHANGE_PSWD_USER}/${idu}`;
  // Make the GET request with the constructed URL
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const forgotPSWD = (payload) =>
  apis.post(API_URLS.FORGOT_PSWD_USER, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPDFCLP = (payload) =>
  apis.post(API_URLS.POST_PDF_CLP, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const getCLP = (codeCLP, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CLP}/${codeCLP}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const downloadApp = async (payload) => {
  try {
    const apiUrl = `${API_URLS.DOWNLOAD_MOBILE_APP}/${payload.ENV}/${payload.os}`;
    const response = await axios.get(apiUrl, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const app_name = `CGEDS_${payload.ENV}_${payload.os}.apk`;
    link.setAttribute('download', app_name); // Nom du fichier à télécharger
    document.body.appendChild(link);
    link.click();

    // Optionnel : Nettoyer après téléchargement
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the APK:', error);
  }
};

export const getEnqueteByCode = (code_enquete, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_ENQUETE_BY_CODE}/${code_enquete}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postAnnexesCLP = (payload) =>
  apis.post(API_URLS.POST_ANNEXES_CLP, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postCLP = (payload) =>
  apis.post(API_URLS.POST_CLP, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const downloadPPDSHP = (nnnnn, payload) => {
  // Construct the URL for fetching the zip file by name
  let apiUrl = `${API_URLS.GET_PPD_SHP}/${nnnnn}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      ...payload?.headers,
    },
    responseType: 'arraybuffer',
  });
};

export const downloadPPPSHP = (nnnnn, payload) => {
  // Construct the URL for fetching the zip file by name
  let apiUrl = `${API_URLS.GET_PPP_SHP}/${nnnnn}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      ...payload?.headers,
    },
    responseType: 'arraybuffer',
  });
};

export const readPPDPDF = (nnnnn, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  let apiUrl = `${API_URLS.GET_PPD_PDF}/${nnnnn}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      ...payload?.headers,
    },
    responseType: 'arraybuffer',
  });
};

export const readPPPPDF = (nnnnn, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  let apiUrl = `${API_URLS.GET_PPP_PDF}/${nnnnn}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      ...payload?.headers,
    },
    responseType: 'arraybuffer',
  });
};

export const patchInfoCF = (payload) => {
  const apiUrl = `${API_URLS.UPDATE_INFO_CF}`;
  // Make the GET request with the constructed URL
  return apis.patch(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const patchStatusDemande = (payload) => {
  const apiUrl = `${API_URLS.UPDATE_STATUS_DEMANDE}`;
  // Make the GET request with the constructed URL
  return apis.patch(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const findUser = (code, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.UPDATE_USER}/${code}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

// export const deleteUser = (payload) =>
//   apis.delete(API_URLS.UPDATE_USER, {
//     ...payload?.data,
//     headers: { "Content-Type": "application/json", ...payload?.headers },
//   });

export const postPDFPVO = (payload) =>
  apis.post(API_URLS.POST_PDF_PVO, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const patchEtatDemande = (payload) =>
  apis.patch(API_URLS.UPDATE_ETAT_DEMANDE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPVO = (payload) =>
  apis.post(API_URLS.POST_PVO, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postANPO = (payload) =>
  apis.post(API_URLS.POST_ANPO, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateANPO = (payload) =>
  apis.post(API_URLS.UPDATE_ANPO, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updatepvo = (code_dcfpvo, payload) =>
  apis.put(`${COMMON_URL}certificat-foncier/update-dcf-pvo/${code_dcfpvo}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPVOPresence = (payload) =>
  apis.post(API_URLS.POST_PVO_PRESENCE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const readPDF = (payload) => {
  // Construct the URL by appending the contract ID to the base URL
  let apiUrl = `${API_URLS.READ_PDF_CF}`;

  // Make the GET request with the constructed URL
  return apis.post(apiUrl, {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      ...payload?.headers,
    },
    responseType: 'arraybuffer',
  });
};

export const postPDFCF = (payload) =>
  apis.post(API_URLS.POST_PDF_CF, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFDCF = (payload) =>
  apis.post(API_URLS.POST_PDF_DCF, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const post_PDF_PVRDC = (payload) =>
  apis.post(API_URLS.POST_PDF_PVRDC, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFCFPiece = (payload) =>
  apis.post(API_URLS.POST_PDF_CFPIECE, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const updatePDFCFPiece = (payload) =>
  apis.put(API_URLS.UPDATE_PDF_CFPIECE, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFConstat = (payload) =>
  apis.post(API_URLS.POST_PDF_CONSTAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const getConstat = (codeconstat, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CONSTAT}/${codeconstat}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postAnnexesConstat = (payload) =>
  apis.post(API_URLS.POST_ANNEXES_CONSTAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postConstat = (payload) =>
  apis.post(API_URLS.POST_CONSTAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateconstat = (code_constat, payload) =>
  apis.put(`${COMMON_URL}constat/update-constat/${code_constat}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

/* export const updateconstat = (code_constat, payload) =>
  apis.put(`${COMMON_URL}constat/update-constat/${code_constat}`, {
    ...payload,
    headers: { "Content-Type": "application/json", ...payload?.headers },
  });
 */

export const postPDFCloturePub = (payload) =>
  apis.post(API_URLS.POST_PDF_CLOTUREPUB, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const createCloturePubPDF = (payload) =>
  apis.post(API_URLS.CREATE_CLOTUREPUB_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPDFPub = (payload) =>
  apis.post(API_URLS.POST_PDF_PUB, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const getAnnoncePub = (codepub, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.ANNONCE_PUB}/${codepub}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postPDFPVCL = (payload) =>
  apis.post(API_URLS.POST_PDF_PVCL, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const getPVCL = (codepvcl, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_PVCL}/${codepvcl}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getLogActivity = (codeId, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.LOG_ACTIVITY}/${codeId}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postDemandesPub = (payload) =>
  apis.post(API_URLS.POST_DEMANDES_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

// export const updateCloturePub = (payload) => {
//   console.log("payload depuis service api ", payload)
//   apis.post(API_URLS.UPDATE_PV_CLOTURE_PUB, {
//     ...payload,
//     headers: { "Content-Type": "application/json", ...payload?.headers },
//   });
// }

export const updateCloturePub = (payload) => {
  return apis.post(API_URLS.UPDATE_PV_CLOTURE_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postCloturePub = (payload) =>
  apis.post(API_URLS.POST_CLOTURE_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postAnnoncePub = (payload) =>
  apis.post(API_URLS.ANNONCE_PUB, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postAnnexesPVCL = (payload) =>
  apis.post(API_URLS.POST_ANNEXES_PVCL, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPVCL = (payload) =>
  apis.post(API_URLS.POST_PVCL, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const getCF = (codecf, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CF}/${codecf}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getPVRDC = async (codecf, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_PVRDC}/${codecf}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getDcfConstats = async (codecf, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_DCF_CONSTATS}/${codecf}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getAnnoncePublicite = async (code_certif, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_DCF_ANNONCE_PUBLICITE}/${code_certif}`;
  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postDemandeCF = (payload) =>
  apis.post(API_URLS.POST_DEMANDE_CF, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postDesignationGestionnaireCFC = (payload) =>
  apis.post(API_URLS.POST_DESIGNATION_GESTIONNAIRECFC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postAEFC = (payload) =>
  apis.post(API_URLS.POST_AEFC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postAEFJ = (payload) =>
  apis.post(API_URLS.POST_AEFJ, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addSignataireDesignationGestionnaireCFC = (payload) =>
  apis.post(API_URLS.ADD_SIGNATAIRE_BIEN_FONCIER, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateSignataireDesignationGestionnaireCFC = (
  code_pvrdc,
  payload
) =>
  apis.put(`${COMMON_URL}pvrdc/update-design-gestion/${code_pvrdc}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPVRDC = (payload) =>
  apis.post(API_URLS.POST_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addMembreEquipEnqPVRDC = (payload) =>
  apis.post(API_URLS.ADD_MEMBRE_EQUIPE_ENQ_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateMembreEquipEnqPVRDC = (payload) =>
  apis.put(API_URLS.UPDATE_MEMBRE_EQUIPE_ENQ_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const deleteMembreEquipEnqPVRDC = (payload) =>
  apis.delete(API_URLS.DELETE_MEMBRE_EQUIPE_ENQ_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addmembreFicheDemo = (payload) =>
  apis.post(API_URLS.POST_FICHE_DEMO_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addDeclarationPVRDC = (payload) =>
  apis.post(API_URLS.ADD_DECLARATION_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addLitigePVRDC = (payload) =>
  apis.post(API_URLS.ADD_LITIGE_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addFicheLitigePVRDC = (payload) =>
  apis.post(API_URLS.ADD_FICHE_LITIGE_PVRDC, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
export const updateFicheLitigePVRDC = async (code_pvrdc, payload) => {
  const apiUrl = `${API_URLS.UPDATE_FICHE_LITIGE_PVRDC}/${code_pvrdc}`;
  apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const addOccupantParcelle = (payload) =>
  apis.post(API_URLS.ADD_OCCUPANT_PARCELLE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const deleteOccupantParcelle = async (
  code_occ_parcelle,
  payload = {}
) => {
  const lastUrl = `${API_URLS.DELETE_OCCUPANT_PARCELLE}/${code_occ_parcelle}`;
  return apis.delete(lastUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updateOccupantParcelle = async (code_occ_parcelle, payload) => {
  const apiUrl = `${API_URLS.UPDATE_OCCUPANT_PARCELLE}/${code_occ_parcelle}`;
  apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const addOccupantParcelleDate = (payload) =>
  apis.post(API_URLS.ADD_OCCUP_PARCELLE_DATE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
//updte
export const updateOccupantParcelleDate = async (code_occ_date, payload) => {
  const apiUrl = `${API_URLS.UPDATE_OCCUP_PARCELLE_DATE}/${code_occ_date}`;
  apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const addListeServitude = (payload) =>
  apis.post(API_URLS.ADD_LISTE_SERVITUDE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addDetenteursDeDroitsCF = (payload) =>
  apis.post(API_URLS.ADD_DETENTEURS_DE_DROITS_CF, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addDateDetenteursDeDroitsCF = (payload) =>
  apis.post(`${COMMON_URL}pvrdc/add-listeddcc`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateDetenteursDeDroitsCF = async (code_pvrdc, payload) => {
  apis.put(`${COMMON_URL}pvrdc/update-listeddcc/${code_pvrdc}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updatePvPublicite = (payload) => {
  const apiUrl = `${COMMON_URL}/certificat-foncier/update-anp`;
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const addEtatDeDroitCF = (payload) =>
  apis.post(API_URLS.ADD_ETAT_DE_DROIT_PROP, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addAyantDroit = (payload) =>
  apis.post(API_URLS.ADD_AYANT_DROIT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postTemoinContrat = (payload) =>
  apis.post(API_URLS.POST_TEMOIN_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateTemoinContrat = (payload) =>
  apis.post(API_URLS.UPDATE_TEMOIN_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postCecpdcPdf = (payload) =>
  apis.post(API_URLS.POST_CECPDC_PDF, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postValidPdf = (payload) =>
  apis.post(API_URLS.POST_VALID_PDF, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const postPDFContrat = (payload) =>
  apis.post(API_URLS.POST_PDF_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const updateProcurationContrat = (payload) =>
  apis.post(API_URLS.UPDATE_PROCURATION_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFCNI = (payload) => {
  return apis.post(API_URLS.POST_PDF_CNI_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });
};

export const updatePDFCNI = (payload) =>
  apis.post(API_URLS.POST_PDF_CNI_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFCroquis = (payload) =>
  apis.post(API_URLS.POST_PDF_CROQUIS_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postPDFProcuration = (payload) => {
  return apis.post(API_URLS.POST_PDF_PROCURATION_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });
};

export const updatePDFProcuration = (payload) =>
  apis.post(API_URLS.UPDATE_PDF_PROCURATION_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const postAnnexesContrat = (payload) => {
  return apis.post(API_URLS.POST_ANNEXES_CONTRAT, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });
};

export const updateAnnexesContrat = (payload) =>
  apis.post(API_URLS.UPDATE_ANNEXES_CONTRAT, {
    ...payload?.data.getAll(),
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const updatePDFCroquis = (payload) =>
  apis.post(API_URLS.UPDATE_ANNEXES_CROQUIS, {
    ...payload?.data,
    headers: { 'Content-Type': 'multipart/form-data', ...payload?.headers },
  });

export const readContractFiles = (payload) => {
  return apis.post(API_URLS.READ_PDF_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
    responseType: 'arraybuffer',
  });
};

export const getContratByEnquete = (codeenquete, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CONTRAT_ENQ}/${codeenquete}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const readpdfprocuration = (idproducration, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.READ_PDF_PROCURATION}/${idproducration}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const readpdfcroquis = (idcroquis, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.READ_PDF_CROQUIS}/${idcroquis}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const readpdfpiece = (idpiece, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.READ_PDF_PIECESID}/${idpiece}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const readPDFAnnexesContrat = (idAnnexe, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.READ_PDF_ANNEXES}/${idAnnexe}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getCertificatByEnquete = (codeEnquete, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CF_BY_ENQ}/${codeEnquete}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getContrat = (codecontrat, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_CONTRAT}/${codecontrat}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postSoussigneContrat = (payload) =>
  apis.post(API_URLS.POST_SOUSSIGNE_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateSoussigneContrat = (person_id, payload) => {
  const apiUrl = `${API_URLS.PUT_SOUSSIGNE_CONTRAT}/${person_id}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const postContrat = (payload) =>
  apis.post(API_URLS.POST_CONTRAT, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateContrat = (code_contrat, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.UPDATE_CONTRAT}/${code_contrat}`;
  // Make the GET request with the constructed URL
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const deleteContrat = (codecontrat, payload) => {
  const apiUrl = `${API_URLS.GET_DELETE_CONTRAT}/${codecontrat}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getEnqueteByUser = (payload) =>
  apis.get(API_URLS.GET_ENQUETE_USER, payload);

export const getEnqueteAll = (payload) =>
  apis.get(API_URLS.GET_ENQUETE_ALL, payload);

export const getOnlyEnqueteAll = (payload) =>
  apis.get(API_URLS.GET_ONLY_ENQUETE_ALL, payload);

export const getContratAll = (payload) =>
  apis.get(API_URLS.GET_CONTRAT_ALL, payload);

export const getCommuneVil = (codecommune, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_COMMUNE_VIL}/${codecommune}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getSpCommune = (codesp, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_SP_COMMUNE}/${codesp}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getDepSp = (codedep, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_DEP_SP}/${codedep}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const regionDep = (coderegion, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_REGION_DEP}/${coderegion}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const getRegions = (payload) => apis.get(API_URLS.GET_REGIONS, payload);

export const postUserResendEmail = (payload) =>
  apis.post(API_URLS.POST_USER_RESEND_EMAIL, payload);

export const postUserConfirmEmail = (payload) =>
  apis.post(API_URLS.POST_USER_CONFIRM_EMAIL, payload);

export const postEnquete = (payload) =>
  apis.post(API_URLS.POST_ENQUETE, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const signinUser = (payload) => apis.post(API_URLS.SIGNIN_USER, payload);

export const signupUser = (payload) => apis.post(API_URLS.SIGNUP_USER, payload);

export const listUser = (payload) =>
  apis.post(API_URLS.LIST_USER, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

// api for Contrat and User Management
const createHeaders = (accessToken) => ({
  Authorization: `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
});

export const listUsersApi = (accessToken) => {
  return apis.get(`${COMMON_URL}user/all`, {
    headers: createHeaders(accessToken),
  });
};

export const listDeteledUsersApi = (accessToken) => {
  return apis.get(`${COMMON_URL}user/all-user-trashed`, {
    headers: createHeaders(accessToken),
  });
};

export const getUserApi = (accessToken, userId) => {
  return apis.get(`${COMMON_URL}user/${userId}`, {
    headers: createHeaders(accessToken),
  });
};

export const changePassword = async (accessToken, userId, data) => {
  try {
    const response = await axios.put(
      `${COMMON_URL}user/change-password/${userId}`,
      data,
      {
        headers: createHeaders(accessToken),
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUserApi = (accessToken, userId) => {
  return apis.delete(`${COMMON_URL}user/${userId}`, {
    headers: createHeaders(accessToken),
  });
};

export const recoveryUserApi = (accessToken, userId) => {
  return apis.put(`${COMMON_URL}user/recover/${userId}`, {
    headers: createHeaders(accessToken),
  });
};

export const getallCf = (payload) =>
  apis.get(`${COMMON_URL}certificat-foncier/cf/all`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const getallDcf = (payload, numero_enquete = null) =>
  apis.get(
    `${COMMON_URL}${numero_enquete
      ? 'certificat-foncier/by-enquete/' + numero_enquete
      : 'certificat-foncier/list-dcf'
    }`,
    {
      ...payload,
      headers: { 'Content-Type': 'application/json', ...payload?.headers },
    }
  );

export const updateUser = async (accessToken, userId, userData) => {
  try {
    const response = await axios.put(`${COMMON_URL}user/${userId}`, userData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};
export const updateFicheDemo = (code_pvrdc, payload) =>
  apis.put(`${COMMON_URL}pvrdc/update-fiche-demo/${code_pvrdc}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addFicheDemoDate = (payload) =>
  apis.post(`${COMMON_URL}pvrdc/add-fiche-demo`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const addSignatairesDesignGestDate = (payload) =>
  apis.post(`${COMMON_URL}pvrdc/add-design-gestion`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updateclp = (code_clp, payload) =>
  apis.put(`${COMMON_URL}clp/update-clp/${code_clp}`, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const updatelppcl = (lppclId, payload) => {
  const apiUrl = `${COMMON_URL}clp/update-clp-lppc/${lppclId}`;
  return apis.put(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const updateUserStatus = async (accessToken, userId, isActive) => {
  try {
    const response = await axios.put(
      `${COMMON_URL}user/active/${userId}`,
      { isActive },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
};

export const updateUserRole = async (accessToken, userId, role) => {
  try {
    const response = await axios.put(
      `${COMMON_URL}user/add-role/${userId}`,
      { role: role },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user role:', error);
    throw error;
  }
};

// statisticsApi.js

export const fetchUserData = async (accessToken) => {
  try {
    const response = await axios.get(`${COMMON_URL}user/all`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.data || [];
  } catch (error) {
    console.error('Error fetching contract data:', error);
    throw error;
  }
};

export const fetchContractData = async (accessToken, numero_enquete = null) => {
  try {
    const response = await axios.get(
      `${COMMON_URL}${numero_enquete ? 'contrat/by-enquete/' + numero_enquete : 'contrat/all'
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return numero_enquete ? response.data || [] : response.data.data || [];
  } catch (error) {
    console.error('Error fetching contract data:', error);
    throw error;
  }
};

export const fetchAllVillages = async (accessToken) => {
  try {
    const response = await axios.get(`${COMMON_URL}enquete/all`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data.data || [];
  } catch (error) {
    console.error('Error fetching all villages:', error);
    throw error;
  }
};

export const findNumeroEnqueteForRegion = async (accessToken, region) => {
  try {
    // Fetch data to get numero_enquete for the selected region
    const response = await axios.get(
      `${COMMON_URL}enquete/by-region/${region}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // Assuming the response is an array
    const data = response.data.data || [];
    return data;
  } catch (error) {
    console.error(`Error fetching numero_enquete for region ${region}:`, error);
    throw error; // Propagate the error for the caller to handle
  }
};

export const fetchCertificateData = async (
  accessToken,
  numero_enquete = null
) => {
  try {
    const response = await axios.get(
      `${COMMON_URL}${numero_enquete
        ? 'certificat-foncier/by-enquete/' + numero_enquete
        : 'certificat-foncier/cf/all'
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return numero_enquete ? response.data || [] : response.data.data || [];
  } catch (error) {
    console.error('Error fetching contract data:', error);
    throw error;
  }
};

export const fetchCertificateDataByEnquete = async (accessToken) => {
  try {
    const response = await axios.get(
      `${COMMON_URL}certificat-foncier/list-dcf`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // console.log(response);
    return response.data || [];
  } catch (error) {
    console.error('Error fetching certificate data:', error);
    throw error;
  }
};

export const fetchDcfData = async (accessToken, numero_enquete = null) => {
  try {
    const response = await axios.get(
      `${COMMON_URL}${numero_enquete
        ? 'certificat-foncier/by-enquete/' + numero_enquete
        : 'certificat-foncier/list-dcf'
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // console.log(response);
      return numero_enquete ? response.data || [] : response.data.data || [];
  } catch (error) {
    console.error('Error fetching contract data:', error);
    throw error;
  }
};

export const getOneOta = (id, payload) => {
  // Construct the URL by appending the contract ID to the base URL
  const apiUrl = `${API_URLS.GET_ONE_OTA}/${id}`;

  // Make the GET request with the constructed URL
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const listAllOtaApi = (payload) => {
  const apiUrl = `${COMMON_URL}ota/all`;
  return apis.get(apiUrl, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });
};

export const addOtaApi = (payload) =>
  apis.post(API_URLS.ADD_OTA, {
    ...payload,
    headers: { 'Content-Type': 'application/json', ...payload?.headers },
  });

export const deleteOtaApi = (accessToken, otaId) => {
  return apis.delete(`${COMMON_URL}ota/${otaId}`, {
    headers: createHeaders(accessToken),
  });
};

export const updateOtaApi = async (accessToken, otaId, otaData) => {
  try {
    const response = await axios.put(`${COMMON_URL}ota/${otaId}`, otaData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const recoveryOtaApi = (accessToken, otaId) => {
  return apis.put(`${COMMON_URL}ota/recover/${otaId}`, {
    headers: createHeaders(accessToken),
  });
};

export const listDeteledOtasApi = (accessToken) => {
  return apis.get(`${COMMON_URL}ota/all-ota-trashed`, {
    headers: createHeaders(accessToken),
  });
};
