import CryptoJS from 'crypto-js';
import compresStorage from './compresStorage';

const SECRET_KEY =
  'p@m0F0r$$445**445p@m0F0r$$445**445p@m0F0r$$445**445p@m0F0r$$445**445p@m0F0r$$445**445';

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const secureStorage = {
  setItem: (key, value) => {
    const encryptedValue = encryptData(value);
    compresStorage.setItem(key, encryptedValue);
  },
  getItem: (key) => {
    const encryptedValue = compresStorage.getItem(key);
    if (encryptedValue) {
      try {
        return decryptData(encryptedValue);
      } catch (e) {
        console.error('Error decrypting data', e);
        return null;
      }
    }
    return null;
  },
  removeItem: (key) => {
    compresStorage.removeItem(key);
  },
  clear: () => {
    compresStorage.clear();
  },
};

export default secureStorage;
